import { useLayoutEffect } from "react"
import { navigate } from 'gatsby';
import { useLocation } from "@reach/router"

export default () => {
  const location = useLocation()

  useLayoutEffect(() => {
    if (location.pathname.includes("handball")) {
      navigate("/handball")
    }

    navigate("/icehockey")
  }, [location.pathname])

  return null;
};
